$baseWhite: #fff8f1;
$baseBlack: #222222;
$button: #333333;
$textWhite: #ffffff;
$titleBlack: #000000;
$footerText: #888888;
$drawer: #ffffff;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
  scroll-behavior: smooth;
}
// Common
.text-white {
  color: $textWhite;
}
// a
a {
  text-decoration: none;
}
// li
li {
  list-style: none;
}
// Section
section, footer {
  padding-left: 5%;
  padding-right: 5%;
}
.section-padding {
  padding: 10% 0;
}
.section-title {
  margin-bottom: 50px;
}
// h2
h2 {
  font-size: 45px;
  color: $titleBlack;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 1;
  text-align: center;
}
.h2-ja {
  font-size: 15px;
  text-align: center;
}
// Text
.text-large {
  text-align: center;
  font-size: 20px;
  line-height: 50px;
  letter-spacing: 3px;
  color: $baseBlack;
}
.text-medium {
  line-height: 34px;
  letter-spacing: 3px;
  font-size: 16px;
  margin: 0 auto;
}
// img
img {
  max-width: 100%;
  height: auto;
}
// inner
.inner {
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
}
// button
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  color: #ffffff;
  font-size: 16px;
}
.button, .submit, .border-button {
  display: block;
  font-size: 16px;
  text-align: center;
  color: $textWhite;
  background: #333333;
  padding: 15px;
  width: 250px;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    box-shadow: 0 4px 6px 0 #888888;
    transform: translateY(-4px);
  }
}
.border-button {
  border: 2px solid $baseBlack;
  background: none;
  color: $baseBlack;
  border-radius: 40px;
  margin: 0 auto;
  &:hover {
    transform: none;
    box-shadow: none;
    background: $baseBlack;
    color: $textWhite;
  }
}
// font-family
body, button {
  font-family: 'Effra', "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: bold;
}
// Drawer
.logo {
  z-index: 99;
  width: 60px;
  position: fixed;
  left: 40px;
  top: 40px;
}
.hamburger {
  height: 20px;
  position: fixed;
  right: 40px;
  top: 40px;
  width: 30px;
  z-index: 99;
  &-line {
    background: $drawer;
    display: block;
    height: 2px;
    position: absolute;
    transition:transform .4s;
    width: 100%;
  }
  &-center {
    top: 9px;
  }
  &-bottom {
    bottom: 0;
  }
  &-top.active {
    top: 8px;
    transform: rotate(45deg);
  }
  &-center.active {
    transform:scaleX(0);
  }
  &-bottom.active {
    bottom: 10px;
    transform: rotate(135deg);
  }
}
// Navigation
.nav {
  background: rgba(0,0,0,0.8);
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 98;
  &-wrapper {
    align-items:center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  &-item {
    margin: 40px 0;
    list-style: none;
  }
  &-link {
    color: $textWhite;
    font-size: 30px;
    font-weight: bold;
    padding: 40px;
    text-decoration: none;
    transition: .5s;
  }
  &-link:hover {
    color: #666666;
  }
}
// メインヴィジュアル
.main-visual {
  background-image: url(../image/main-visual.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative;
  h1 {
    height: 550px;
    font-size: 40px;
    color: $baseWhite;
    text-shadow: 0 0 40px #000000;
    writing-mode: vertical-rl;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    line-height: 100px;
    letter-spacing: 10px;
  }
}
// 理念
.philosophy {
  background: $baseWhite;
  &-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &-text {
    margin-bottom: 10%;
  }
  &-image {
    width: 32%;
  }
}
// About Us
.about-us {
  background-image: url(../image/about-bg.jpg);
  .inner {
    width: 900px;
  }
  &-container {
    display: flex;
    justify-content: space-between;
  }
  &-left, &-right {
    width: 45%;
  }
}

// Adventures
.adventures {
  background: $baseWhite;
  &-left,
  &-right {
    width: 48%;
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-h2 {
    text-align: center;
  }
  &-about {
    margin-bottom: 5%;
  }
  &-more {
    margin-bottom: 10%;
  }
}
// Trek America
.trek-america {
  margin-bottom: 70px;
  &-reference {
    text-decoration: underline;
  }
  &-h2 {
    text-align: left;
  }
  &-text {
    margin-bottom: 50px;
  }
}
// G Adventures
.g-adventures {
  .adventures-left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
  &-h2 {
    text-align: left;
  }
  &-text {
    margin-bottom: 50px;
  }
  &-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// Fixed Background Image
.fixed-bg {
  background-image: url(../image/fixed-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 300px;
  width: 100%;
}
// 大人の修学旅行
.otona {
  background: #ffffff;
  &-container {
    display: flex;
    justify-content: space-between;
  }
  &-left,
  &-center,
  &-right {
    width: 30%;
  }
  &-sp {
    display: none;
  }
  &-right &-image {
    margin-top: 100px;
  }
  &-contact {
    margin: 0 auto;
  }
  &-text {
    margin-bottom: 50px;
  }
}
// 中村隊長 ミラタビ 紹介
.introduce {
  padding: 0;
  .inner {
    width: 100%;
  }
  &-container {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
  }
  &-text {
    background-position: center;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    position: relative;
    padding-right: 7%;
    padding-left: 7%;
  }
  &-image {
    padding: 0 25%;
  }
  &-taicho {
    background-image: url(../image/taicho-bg.jpg);
  }
  &-miratabi {
    background-image: url(../image/miratabi-bg.jpg);
  }
  &-bottom {
    margin-bottom: 50px;
  }
  &-taicho-text,
  &-miratabi-text {
    margin-bottom: 50px;
  }
  &-h2 {
    color: $textWhite;
  }
  .text-medium {
    text-align: center;
    color: $textWhite;
  }
  .border-button {
    border: 2px solid $textWhite;
    color: $textWhite;
    &:hover {
      background: $textWhite;
      color: $baseBlack;
    }
  }
}
// Concept
.concept {
  background: $baseWhite;
  &-25 {
    display: flex;
  }
  &-25-wrapper {
    color: $baseBlack;
    background: #ffffff;
    line-height: 25px;
    letter-spacing: 2px;
    margin: 0 auto;
    padding: 8%;
    font-size: 13px;
    background-image: url(../image/25-article-bg.jpg);
  }
  &-25-title {
    margin-bottom: 10%;
  }
  &-about {
    margin-bottom: 10%;
  }
}
// Contact
.contact {
  background-image: url(../image/contact-bg.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  .inner {
    width: 670px;
  }
  h2, .h2-jp {
    color: $textWhite;
  }
  &-container {
    background: #ffffff;
    padding: 50px;
  }
  .label {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 50px;
    padding-top: 13px;
    vertical-align: top;
    width: 177px;
    span, .any{
      font-size: 14px;
      color: $textWhite;
      padding: 3px;
      background-color: #ff4e4e;
      border-radius: 5px;
    }
    .any {
      background: #999999;
    }
  }
  .text, .select, .textarea-text {
    display: inline-block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: none;
    border: 1px solid $baseBlack;
    border-radius: 0;
    margin-bottom: 50px;
    color: inherit;
    font-family: inherit;
    font-size: 16px;
    padding: 16px;
    width: calc(100% - 177px - 50px);
    vertical-align: middle;
  }
  .text:focus {
    box-shadow: none;
    outline: none;
  }
  .textarea-text {
    resize: vertical;
    height: 200px;
  }
  .textarea-text:focus {
    border: 1px solid $baseBlack;
    box-shadow: none;
    outline: none;
  }
  .select::-ms-expand {
    display: none;
  }
  .select:focus {
    border: 1px solid $baseBlack;
    box-shadow: none;
    outline: none;
  }
  .submit {
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
    margin: 0 auto;
  }
  .submit::-moz-focus-inner {
    border: none;
    padding: 0;
  }
  p {
    text-align: center;
    margin-bottom: 50px;
    font-size: 16px;
    a {
      color: #666666;
    }
  }
  .checkbox {
    border-radius: 0;
    width: 30px;
    margin-bottom: 50px;
  }
  span a {
    color: $baseBlack;
  }
}
// Footer
.footer {
  background: $baseBlack;
  height: 200px;
  position: relative;
  padding: 50px 0;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-wrapper {
    width: 100%;
  }
  &-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    list-style: none;
    text-align: center;
  }
  &-link {
    color: $textWhite;
    text-decoration: none;
    margin: 10px;
    transition: all 0.3s ease 0s;
    &:hover {
      color: $footerText;
    }
  }
  &-logo-sns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    bottom: 0;
  }
  &-logo {
    width: 60px;
    position: static;
    z-index: auto;
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &-copyright, a {
    color: #888888;
    bottom: 0;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
  }
  &-sns {
    bottom: 0;
    align-items: center;
    .fa-twitter-square, .fa-facebook-square, .fa-instagram {
      color: $textWhite;
      margin: 0 10px;
      transition: all 0.3s ease 0s;
    }
    .fa-twitter-square:hover {
      color: #1da1f2;
    }
    .fa-facebook-square:hover {
      color: #3c5a99;
    }
    .fa-instagram:hover {
      color: #cf2e92;
    }
  }
}
