$adventuresWhite: #ffffff;
$textShadow: #000000;
// inner
$layout-width-inner: 1086px;
// Break Point
$breakpoints: (
  "sp": "screen and (max-width: 767px)",
  "tab": "screen and (max-width: #{$layout-width-inner - 1px})",
  "pc": "screen and (min-width: #{$layout-width-inner})",
);
// mixin
@mixin sp($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin tab($breakpoint: tab) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin pc($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
// タブレット
@include tab {
  // About Us
  .about-us {
    &-container {
      display: block;
      text-align: center;
    }
    &-left, &-right {
      width: 100%;
    }
    &-left {
      margin-bottom: 50px;
    }
    .inner {
      width: 80%;
    }
    br {
      display: none;
    }
  }
  // 多国籍ツアー
  .adventures {
    &-wrapper {
      display: inline-block;
    }
    &-left,
    &-right {
      width: 100%;
    }
    .trek-america,
    .g-adventures {
      &-about {
        margin-top: 50px;
      }
    }
    .g-adventures {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  // 大人の修学旅行
  .otona {
    &-right {
      display: none;
    }
    &-left {
      width: 45%;
      text-align: center;
      margin-bottom: 10%;
    }
    &-center {
      width: 45%;
      padding-top: 15%;
    }
  }
  // Footer
  .footer {
    padding-bottom: 40px;
    .footer-menu {
      margin-bottom: 40px;
    }
  }
}
// Smart Phone
@include sp {
  .section-padding {
    padding: 15% 0;
  }
  // h2 {
  h2 {
    font-size: 25px;
  }
  // Button
  .button, .submit, .border-button  {
    width: 175px;
    border-radius: 100px;
    margin: 0 auto;
    box-shadow: 0 4px 6px 0 #888888;
    transform: translateY(-4px);
  }
  // Text
  .text-large {
    font-size: 16px;
  }
  .text-medium {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
  //
  // Main Visual
  .main-visual h1 {
    margin-top: 130px;
    font-size: 20px;
    line-height: 40px;
  }
  // 理念
  .philosophy {
    &-text {
      font-size: 14px;
      line-height: 40px;
    }
  }
  // 多国籍ツアー
  .adventures {
    .trek-america {
      margin-bottom: 10%;
    }
    .text-medium {
      margin-bottom: 10%;
    }
    .border-button {
      margin-bottom: 10%;
    }
    .trek-america, .g-adventures {
      h2 {
        text-align: center;
        margin: 20px 0 20px 0;
      }
      .text-medium {
        margin-bottom: 10%;
      }
      .right, .left {
        padding: 0;
      }
    }
  }
  .about-us {
    &-container {
      display: block;
    }
    &-left,
    &-right {
      width: 100%;
    }
    &-left {
      margin-bottom: 50px;
    }
  }
  // Fixed Background Image
  .fixed-bg {
    height: 100px;
  }
  // 大人の修学旅行
  .otona {
    &-container {
      display: inline;
    }
    &-left {
      width: 100%;
    }
    &-center,
    &-right {
      display: none;
    }
    &-center {
      margin-top: 0;
    }
    &-sp {
      display: block;
    }
  }
  // Introduce
  .introduce {
    &-container {
      display: block;
    }
    &-taicho,
    &-miratabi {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
  // Concept
  .concept {
    .text-medium {
      margin-bottom: 45px;
    }
    .about {
      padding: 20px;
    }
    li {
      display:block;
      font-size: 10px;
    }
    .text-large {
      margin-top: 20px;
      line-height: normal;
    }
  }
  // Contact Form
  .contact {
    &-container {
      margin: 0 5%;
    }
    .label {
      display: block;
      margin: 0 0 20px 0;
    }
    .text, .select, .textarea-text {
      width: 100%;
    }
  }
  // Footer
  .footer {
    height: 100%;
    padding: 10% 0;
    &-logo-sns {
      margin-bottom: 20px;
    }
    .footer-menu {
      display: none;
    }
  }
}



